export const eventsList = [
  {
    id: 1,
    title: "Événements",
    frontImage: "https://unsplash.it/500/500/",
    backImage: "https://unsplash.it/500/500/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "events",
  },
  {
    id: 2,
    title: "Activités",
    frontImage: "https://unsplash.it/511/511/",
    backImage: "https://unsplash.it/511/511/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "activities",
  },
  {
    id: 3,
    title: "Soirées",
    frontImage: "https://unsplash.it/502/502/",
    backImage: "https://unsplash.it/502/502/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "evenings",
  },
];
export const servicesList = [
  {
    id: 1,

    title: "Santé & bien-être",
    frontImage: "https://unsplash.it/503/503/",
    backImage: "https://unsplash.it/503/503/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "health",
  },
  {
    id:2,
    title: "Alimentation",
    frontImage: "https://unsplash.it/504/504/",
    backImage: "https://unsplash.it/504/504/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "food",
  },
  {
    id:3,
    title: "Prêt-à-porter",
    frontImage: "https://unsplash.it/505/505/",
    backImage: "https://unsplash.it/505/505/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "readytowear",
  },
  {
    id:4,
    title: "Services",
    frontImage: "https://unsplash.it/506/506/",
    backImage: "https://unsplash.it/506/506/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "services",
  },
  {
    id:5,
    title: "Communautés",
    frontImage: "https://unsplash.it/507/507/",
    backImage: "https://unsplash.it/507/507/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "community",
  },
  {
    id:6,
    title: "Lieux de culte",
    frontImage: "https://unsplash.it/508/508/",
    backImage: "https://unsplash.it/508/508/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "places",
  },
  {
    id:7,
    title: "Salles à louer",
    frontImage: "https://unsplash.it/509/509/",
    backImage: "https://unsplash.it/509/509/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "rentalspaces",
  },
];

export const cultureList = [
  {
    id:1,
    title: "Associations",
    frontImage: "https://unsplash.it/510/510/",
    backImage: "https://unsplash.it/510/510/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "associations",
  },
  {
    id:2,
    title: "Cours",
    frontImage: "https://unsplash.it/511/511/",
    backImage: "https://unsplash.it/511/511/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "courses",
  },
];
export const placesList = [
  {
    id:1,
    title: "Restaurants",
    frontImage: "https://unsplash.it/514/514/",
    backImage: "https://unsplash.it/514/514/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "restaurants",
  },
  {
    id:2,
    title: "Épiceries",
    frontImage: "https://unsplash.it/515/515/",
    backImage: "https://unsplash.it/515/515/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "groceries",
  },
  {
    id:3,
    title: "Pâtisseries",
    frontImage: "https://unsplash.it/516/516/",
    backImage: "https://unsplash.it/516/516/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "pastryshops",
  },
  {
    id:4,
    title: "Nos Adresses",
    frontImage: "https://unsplash.it/517/517/",
    backImage: "https://unsplash.it/517/517/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "ouraddresses",
  },
  {
    id:5,
    title: "Gastronomie",
    frontImage: "https://unsplash.it/518/518/",
    backImage: "https://unsplash.it/518/518/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "gastronomy",
  },
  {
    id:6,
    title: "Streetfood",
    frontImage: "https://unsplash.it/519/519/",
    backImage: "https://unsplash.it/519/519/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "streetfood",
  },
  {
    id:7,
    title: "Restaurant du mois",
    frontImage: "https://unsplash.it/520/520/",
    backImage: "https://unsplash.it/520/520/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "restaurantofthemonth",
  },
  {
    id:8,
    title: "Nouveautés",
    frontImage: "https://unsplash.it/521/521/",
    backImage: "https://unsplash.it/521/521/",
    description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.",
    type: "news",
  },
];
