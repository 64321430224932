import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./card.css";

export default function ActionAreaCard(props) {
  const { image, title, location, description, status , tel,page } = props;

  return (
<div className={`restaurant`} style={{borderTop:   status === 'Closed' ? '10px solid #c02a2c' : status === 'Coming Soon' ? '10px solid blue ' : '10px solid #2f7509'}}>
      <div className="infos_left">
        <div className="image_container" style={{ backgroundImage: `url(${image.startsWith("http")?image:`${process.env.REACT_APP_IMAGE_URL}${image}`})` }}>
        </div>
        
        <div className="prix_block" style={{backgroundColor: status === 'Closed' ? '#c02a2c' : status === 'Coming Soon' ? 'blue' : '#2f7509', display: page=== 'event' ? 'block' : 'none'}}>
        <span className="text_prix" >{status}</span>
        </div>
        <div className="block_infos">
          <h1>{title}</h1>
          <div className="adress_block">  
          {" "}

            <span className="adress">
          <FontAwesomeIcon icon={faLocationDot} />
              {location}</span>
            {" "}
            <span className="adress">
            <FontAwesomeIcon icon={faPhone} />
              {tel}</span>
          </div>
          <div className="description_block">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
