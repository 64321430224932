import "./success.css"
function Successfully() {
    return ( <>
     <div className="success-page">
      <h1>Form Submitted Successfully!</h1>
      <p>Your form has been submitted and will be checked and confirmed.</p>
    </div>
    </> );
}

export default Successfully;